
.uwy.utb .uai img, body .uwy.utb .uai img {
	width: 40px !important;
	height: 40px !important;
}
body .uwy.utb .uai {
	padding: 4px !important;
	width: 40px !important;
	height: 40px !important;
	min-width: 40px !important;
	min-height: 40px !important;
	max-width: 40px !important;
	max-height: 40px !important;
}
body .uwy.userway_p6 .uai {
	top: auto !important ;
	transform: translateY(-50%);
	left: auto !important;
	right: 13px !important;
	bottom: 0px !important;
}
h1, h2, h3 {
	font-family: 'Oswald', sans-serif;
}
.banner_video_section{
	position: relative;
	overflow: hidden;
	width: 100%;
}
.banner_video_section video {
	width: 100%;
	z-index: 1;
  }
  .banner_video_content {
	position: absolute;
	z-index: 9;
	left: 0;
	top: 25px;
	max-width: 1400px;
	margin: auto !important;
	right: 0;
}
.home_vmenu {
	float: right;
}
.home_vmenu_ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.home_vmenu_ul li {
	display: inline-block;
}
.home_vmenu_ul li a {
	padding: 15px;
	display: block;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
}
.home_vmenu_ul li a i {
	font-weight: 500;
}
.home_section3 {
	margin: 50px auto;
	text-align: center;
}
.home_section4{
	margin: 50px auto;
}
.home_section3 h2 {
	font-weight: 500;
	font-size: 60px;
	letter-spacing: 2px;
}
.home_section5 h2 {
	text-align: center;
	font-size: 50px;
	margin-bottom: 50px;
}
.home_section5, .home_section6, .home_section7, .home_section8{
	margin: 50px auto;
}

.order_btn {
	display: block;
	border: 3px solid #000;
	padding: 15px;
	text-align: center;
	color: #000;
	font-weight: bold;
	max-width: 300px;
	margin: 50px auto;
	text-transform: uppercase;
	letter-spacing: 2px;
	text-decoration: none;
}
.order_btn_new {
	display: block;
	padding: 15px;
	text-align: center;
	font-weight: bold;
	max-width: 300px;
	margin: 50px auto;
	text-transform: uppercase;
	letter-spacing: 2px;
	background-color: #59bacb;
	color: #fff;
	text-decoration: none;
}
.order_btn_new:hover {
	text-decoration: none;
	color:#fff;
}
.order_btn:hover {
	background-color: #000;
	color:#fff;
}
.home_section6 .box {
	background: #75c6de;
	padding: 50px;
}
.home_section6 .box img {
	width: 300px;
}
.home_section6 .single_box {
	text-align: center;
}
/*buy-now section*/
.buy_now h2 {
	font-weight: 500;
	font-size: 50px;
	letter-spacing: 2px;
	padding-bottom: 15px;
}
.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh; 
}
.circle {
    width: 210px;
    height: 210px;
    border-radius: 50%; 
   /* background-color: #3498db;*/
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px; 
	cursor:pointer;
   /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */
}
.circle h3 {
    margin: 0;
    /*font-size: 1.2rem; */
}
.PPO {
	margin: 0px 37px !important;
	text-align: center;
}.self-cir {
	background-color: #b59ed8;
}
.ppo-cir {
	background-color: #fff15a;
}
.buy_now {
	padding: 50px 0px;
}
@media screen and (max-width: 768px) {
    .circle {
        width: 150px;
        height: 150px;
    }

    .circle h3 {
        font-size: 16px;
    }
}
/* .ins_btn {
	border: 2px solid #000;
	padding: 10px 15px;
	color: #000;
} */
a.ins_btn {
	/* border: 2px solid #000; */
	padding: 10px 15px;
	color: #fff;
	background: #59bacb;
	text-decoration: none;
	text-transform: uppercase;
}
.j_container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.j_item {
	margin: 15px 0;
}
/*# sourceMappingURL=styles.css.map*/




.header_menu_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}
.insurance label {
	display: block;
	max-width: 100%;
	margin-bottom: 15px;
	font-weight: 400;
}
.step {
	text-align: left;
}
ul.parsley-errors-list li {
	padding: 3px 10px;
}
ul.parsley-errors-list {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-top: 5px;
	border-radius: 4px;
	overflow: hidden;
}
.crol {
	width: 100%;
	margin: 0;
	height: 180px;
	overflow: auto;
	padding: 10px 15px;
	border: 1px solid #ccc;
}
.insurance {
	width: 100%;
	margin: 40px auto;
	border: 1px solid #000;
	background-color: #fff;
}
.step {
	padding: 14px;
	margin-bottom:0 !important;
}
.insurance h3 {
	margin-bottom: 0px;
	background-color: #000;
	padding: 12px 15px;
	margin-top: 0;
	color: #fff;
}
.insurance h4 {
	margin-bottom: 20px;
	background-color: #faebd7;
	margin-top: 20px;
	padding: 10px 16px;
}
.btn.btn-md.btn-primary {
	margin-top: 30px;
}
#signature1 {
	width: 100%;
	height: 98px;
}
.submit-button {
	background: #1b9397 !important;
	padding: 10px 38px;
	color: #fff;
	letter-spacing: 2px;
	border: 1px solid #1b9397;
	border-radius: 3px;
	text-transform: uppercase;
}
.insurance p {
	font-size: 16px;
	font-weight: normal;
	padding-top: 0;
}
.terms {
	margin-top: 15px;
	font-size: 18px;
}
.parsley-required {
	color: red;
	padding:10px 0;
}